<template>
	<div class="excursions pb-12">
		<v-container class="vuetify-container">
			<h1 class="section-title playfair w-100 dark-text mb-0" data-aos="fade-left" data-aos-duration="700">Izleti</h1>
			<p class="text--secondary w-100" data-aos="fade" data-aos-duration="1700">Ispunite dane našom bogatom ponudom izleta!</p>
			<v-divider class="mb-md-10 mb-5 w-100"></v-divider>

			<v-row class="w-100 ma-0">
				<v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in $store.getters.excursions" :key="index"
					class="flex-center flex-column" @click="goTo('/excursions/' + item.slug)" data-aos="fade" data-aos-duration="1500">
					<img :src="getImage(item.image)" :alt="item.name" class="excursion-image mb-5">
					<h2 class="w-100 text-center dark--text font-weight-light mb-0">
						{{ item.city }}
					</h2>
					<p class="dark-text caption font-weight-light w-100 text-center">{{ item.distance }}km od Neuma</p>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
export default {
	name: 'Excursions',
	data: () => ({
		//
	}),
	methods: {
		goTo(link) {
			this.$router.push(link);
		},
		getImage(image) {
			return require('../assets/img/excursions/covers/' + image);
		}
	}
}
</script>